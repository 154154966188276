import LocalizedStrings from 'react-localization';
import { IStrings } from './IStrings';
import { nl } from './Nl';
import { fr } from './Fr';
import { en } from './En';

export const Strings = new LocalizedStrings<IStrings>({
    nl: nl,
    fr: fr,
    en: en,
})