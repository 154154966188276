import React from 'react';
import { IStopView } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { DefaultDateFormat } from '../../../constants';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import moment from 'moment';
import './StopViewComponent.scss';

interface IProps {
    stop: IStopView;
    onEdit?: () => void;
    onDelete?: () => void;
    formatStopAsLabel: boolean;
}
export const StopViewComponent = ({ stop, onEdit, onDelete, formatStopAsLabel }: IProps) => {
    const locContext = useLocalizationContext();
    const stopName = stop.isFirst ? locContext.firstStop : stop.isLast ? locContext.lastStop : locContext.extraStop + ' ' + stop.order;
    const formatDateSpecifier = (str: string | undefined) => {
        if (str === 'At') {
            return locContext.at;
        } else if (str === 'Before') {
            return locContext.before;
        } else {
            return locContext.after;
        }
    }
    return (
        <div className="df-row jc-sb stop-view">
            <div className="df-col fg1">
                {/* <div className={formatStopAsLabel ? `label${stop.isFirst ? '' : ' without-margin'}` : 'font-bold'}>{stopName}</div> */}
                <div className={formatStopAsLabel ? `label` : 'font-bold'}>{stopName}</div>
                <div>{moment(stop.date, DefaultDateFormat).format(DefaultDateFormat)} {formatDateSpecifier(stop.dateSpecifier)} {moment(stop.date, DefaultDateFormat).format('HH:mm')}</div>
                <div>{stop.address ? stop.address.company + ' - ' + stop.address.contact : ''}</div>
                <div>{stop.address ? stop.address.formatted : ''}</div>
                {stop.address && stop.address.phoneNumber ? <div>{stop.address.phoneNumber}</div> : null}
                {stop.address && stop.address.additionalInfo ? <div>{stop.address.additionalInfo}</div> : null}
                {stop.address && stop.address.comment ? <div>{stop.address.comment}</div> : null}
            </div>
            {onEdit && onDelete ?
                <div className="df-col">
                    <TextLinkButton gray className="stop-button" onClick={onEdit}>{locContext.change}</TextLinkButton>
                    <TextLinkButton gray className="stop-button" onClick={onDelete}>{locContext.delete}</TextLinkButton>
                </div>
                :
                null}
        </div>
    );
}