import React, { useState, useContext } from 'react';
import { TransportContext } from '../../../context/TransportSubmitContext';
import { IAddressModel, AddressModel, ValidateClient, IStopModel } from '../../../gen/ApiClients';
import { DateComponent } from '../common/DateComponent';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { validateTimings } from '../common/Utils';
import { useClient } from '../../../hooks/useClient';
import { AddressFormComponent } from '../common/addresses/AddressFormComponent';

export const Step2 = () => {
    const validateClient = useClient(ValidateClient);
    const locContext = useLocalizationContext();
    const context = useContext(TransportContext);
    const [model, setModel] = useState<IStopModel | undefined>(context.state.lastStop);

    const handleSetAddress = (address: IAddressModel) => {
        setModel({ ...model, address: new AddressModel(address) });
        context.nextSubstep();
    }
    const handleSetDate = async (date: Date, specifier: string) => {
        const x = { ...model, date: date, dateSpecifier: specifier }
        setModel(x);
        await context.setLastStop(x);
    }

    const render = () => {
        if (context.subStep === 0) {
            return <AddressFormComponent
                header={`${locContext.unload}: ${locContext.addressDetails}`}
                address={model && model.address ? model.address : undefined}
                previousText={locContext.previous}
                submit={handleSetAddress}
                onPrevious={() => context.goBackToStep(1)} />
        } else if (context.subStep === 1 && context.state.id) {
            return <DateComponent
                header={`${locContext.unload}: ${locContext.selectDateAndTime}`}
                date={model && model.date ? model.date : new Date()}
                specifier={model && model.dateSpecifier ? model.dateSpecifier : 'Before'}
                onSubmit={handleSetDate}
                onPrevious={() => context.previousSubStep()}
                validate={(date, spec) => validateTimings(validateClient, context.state.id ? context.state.id : '', context.state.lastStop ? context.state.lastStop.id : undefined, model, date, spec)} />
        }
        return <div>nothing found</div>
    }

    return (
        render()
    );
}