import { IStrings } from './IStrings';

export const en: IStrings = {
    add: `Add`,
    addressDetails: `Address details`,
    addStop: `Add stop`,
    after: `After`,
    alsoFillInAHouseNumber: `Please fill in a housenumber`,
    at: `At`,
    before: `Before`,
    busySearchingAddress: `Search address`,
    busyTyping: `Busy typing`,
    cancel: `Cancel`,
    change: `Adjust`,
    checkYourData: `Check your data`,
    choose: `Choose`,
    comments: `Comments`,
    companyName: `Company name`,
    confirmAddStopover: `Do you want to add an additional stop?`,
    confirmDeleteStop: `Are you sure you want to delete this stop?`,
    contactMe: `Contact me`,
    date: `Date`,
    delete: `Delete`,
    deleteStop: `Delete stop`,
    email: `Email`,
    extraStop: `Extra stop`,
    fileNumber: `Order number`,
    fillIn: `Fill in`,
    firstStop: `First stop`,
    goToUnload: `Go to unload`,
    hours: `Hours`,
    houseNumber: `House number`,
    labelAdditionalInfo: `Additional info (busnr, floor, ...)`,
    labelContactPerson: `Contact person on the spot`,
    labelStreetNumberCityAndCode: `Address (street, house number, city and postal code)`,
    lastStop: `Last stop`,
    leavePageDescription: `When you leave this page all your modifications will be lost. Are you sure?`,
    leavePageTitle: `Leave page`,
    load: `Load`,
    mailSent: `Your request has been sent to Euro-Sprinters!`,
    max6stops: `You can enter up to max. 6 stops.\nWant to add more? Contact customer service.`,
    maxLengthValidation: `Length is limited to max. {0} characters`,
    next: `Next`,
    no: `No`,
    noAddressFound: `No address found`,
    noStay: `No, stay on this page`,
    optional: `Optional`,
    overview: `Overview`,
    phoneNumber: `Phone number`,
    placeholderAdditionalInfo: `Additional info`,
    placeholderAddress: `Fill in address`,
    placeholderCompany: `Fill in company name`,
    placeholderContactPerson: `Fill in the name of the contact person`,
    placeholderPhonenumber: `Fill in phone number`,
    previous: `Previous`,
    price: `Price`,
    route: `Route`,
    search: `Search`,
    select: `Select`,
    selectAnAddress: `Select an address`,
    selectDateAndTime: `Select date & time`,
    selectTime: `Select time`,
    send: `Send`,
    serverError: `An unknown error has occured. Please try again. If this error returns, please contact Euro-Sprinters!`,
    startNew: `Start over`,
    stopover: `Additional stop`,
    time: `Time`,
    unload: `Unload`,
    validateCompanyName: `Fill in your company name`,
    validateContactPerson: `Fill in contact person`,
    validateHouseNumberIfNotFoundAbove: `Fill in a house number when it hasn't been found above`,
    validateTime: `Selete a time of day`,
    validateTimeSelector: `Select from /at/after`,
    vatNumber: `VAT-nummer`,
    yes: `Yes`,
    yesDelete: `Yes, delete`,
    yesLeave: `Yes, leave`,
}
