import React from 'react';
import { FieldGroup, IFieldGroupProps } from './FieldGroup';
import { AddressTypeAhead } from '../addressTypeAhead/AddressTypeAhead';
import { ISuggestion } from '../../../gen/ApiClients';
import './AddressTypeAheadField.scss';

interface IProps<T> extends IFieldGroupProps<T> {
    value: ISuggestion | undefined;
    onValueChanged: (val: ISuggestion | undefined) => void;
    warningMessage: string;
}

export function AddressTypeAheadField<T>({ value, onValueChanged, warningMessage, ...rest }: IProps<T>) {
        return (
            <FieldGroup {...rest}>
                <AddressTypeAhead
                    placeholder={rest.placeholder}
                    onSelect={(x) => onValueChanged(x)}
                    selected={value}
                    clearSelect={() => onValueChanged(undefined)} />
                {value && value.isExact === false ? 
                <span className="warning-message">{warningMessage}</span>
                : null}
            </FieldGroup>
        );
}