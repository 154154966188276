import { Form, Formik, FormikActions } from 'formik';
import moment from 'moment';
import React from 'react';
import * as yup from 'yup';
import { DefaultDateFormat } from '../../../constants';
import { IValidateResponse } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { setFieldError } from '../../common/forms/FormsUtils';
import { SelectDate } from '../../common/forms/SelectDate';
import { SelectTimeWithSpecifier } from '../../common/forms/SelectTimeWithSpecifier';
import { tryCatchWithLoading } from '../../../infrastructure/Utils';

interface IModel {
    date: Date;
    time: string;
    specifier: string;
}

interface IProps {
    header: string;
    date: Date | undefined;
    specifier: string;
    onSubmit: (date: Date, specifier: string) => Promise<any>;
    onPrevious: () => void;
    modal?: boolean;
    validate: (date: Date, dateSpecifier: string) => Promise<IValidateResponse>;
}

export const DateComponent = ({ validate, header, date, specifier, onSubmit, onPrevious, modal = false }: IProps) => {
    const locContext = useLocalizationContext();
    const initialValue: IModel = { time: date ? moment(date, DefaultDateFormat).format('HH:mm') : '', specifier: specifier ? specifier : '', date: date ? date : new Date() };
    const schema = yup.object<IModel>().shape<IModel>({
        time: yup.string()
            .required(locContext.validateTime),
        specifier: yup.mixed()
            .required(locContext.validateTimeSelector),
        date: yup.date()
            .required(locContext.validateTime),
    });
    const handleSubmit = async (model: IModel, actions: FormikActions<IModel>) => {
        var splits = model.time.split(':');
        let m = moment(model.date).set({ hour: Number(splits[0]), minute: Number(splits[1]), second: 0, millisecond: 0 }).toDate();
        const response = await tryCatchWithLoading(validate(m, model.specifier), () => {}, locContext.serverError);
        actions.setSubmitting(false);
        if (response === undefined || response === null) {
            return;
        }
        if (response.hasError) {
            setFieldError<IModel>('time', response.errorMessage ? response.errorMessage : 'Error', actions);
        } else {
            await onSubmit(m, model.specifier);
        }
    }

    return (
        <div className="blank-container">
            <div className="header-text">{header}</div>
            <Formik<IModel>
                initialValues={initialValue}
                validationSchema={schema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="pos-rel">
                            <SelectDate<IModel> label={locContext.date} xName="date" />
                            <SelectTimeWithSpecifier<IModel> label={locContext.hours} specifierName="specifier" xName="time" />
                            <div className={`df-row-ac blank-container-buttons ${modal ? 'jc-e' : 'jc-c'}`}>
                                <div className="df-row-ac">
                                    <TextLinkButton className="previous-button" onClick={onPrevious}>{locContext.previous}</TextLinkButton>
                                    <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit">{locContext.next}</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}