import { ValidateClient, IStopModel, IValidateResponse, ValidateTimingsModel } from '../../../gen/ApiClients';

export async function validateTimings(
	client: ValidateClient,
	transportId: string,
	stopId: string | undefined,
	model: IStopModel | undefined,
	date: Date,
	dateSpecifier: string
): Promise<IValidateResponse> {
	return await client.validateTimings(
		transportId,
		new ValidateTimingsModel({
			date: date,
			dateSpecifier: dateSpecifier,
			stopId: stopId,
			address: model?.address,
		})
	);
}
