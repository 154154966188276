import React, { useContext, useState } from "react";
import { TransportContext } from "../../../context/TransportSubmitContext";
import { DateComponent } from "../common/DateComponent";
import { StopsView } from "./StopsView";
import { useLocalizationContext } from "../../../hooks/useLocalizationContext";
import { useClient } from "../../../hooks/useClient";
import {
  ValidateClient,
  IStopModel,
  IAddressModel,
  AddressModel,
  ValidateResponse,
  ValidateLeadTimeModel,
} from "../../../gen/ApiClients";
import { AddressFormComponent } from "../common/addresses/AddressFormComponent";
import "./Step1.scss";

export const Step1 = () => {
  const validateClient = useClient(ValidateClient);
  const locContext = useLocalizationContext();
  const context = useContext(TransportContext);
  const [model, setModel] = useState<IStopModel | undefined>(
    context.state.firstStop
  );

  const handleSetAddress = (address: IAddressModel) => {
    setModel({ ...model, address: new AddressModel(address) });
    context.nextSubstep();
  };
  const setFirstStop = async (date: Date, specifier: string) => {
    const x = { ...model, date: date, dateSpecifier: specifier };
    setModel(x);
    await context.setFirstStop(x);
    context.nextSubstep();
  };
  const validateLeadTime = async (
    date: Date,
    dateSpecifier: string
  ): Promise<ValidateResponse> => {
    return await validateClient.validateLeadTime(
      new ValidateLeadTimeModel({
        date: date,
        dateSpecifier: dateSpecifier,
        address: model?.address,
      })
    );
  };

  const render = () => {
    if (context.subStep === 0) {
      return (
        <AddressFormComponent
          header={`${locContext.load}: ${locContext.addressDetails}`}
          address={model && model.address ? model.address : undefined}
          submit={handleSetAddress}
        />
      );
    } else if (context.subStep === 1) {
      return (
        <DateComponent
          header={`${locContext.load}: ${locContext.selectDateAndTime}`}
          date={model && model.date ? model.date : new Date()}
          specifier={
            model && model.dateSpecifier ? model.dateSpecifier : "After"
          }
          onSubmit={setFirstStop}
          onPrevious={() => context.previousSubStep()}
          validate={validateLeadTime}
        />
      );
    } else if (context.subStep === 2) {
      return (
        <StopsView
          onPrevious={() => context.previousSubStep()}
          onNext={() => context.gotoStep2()}
        />
      );
    }
    return <div>nothing found</div>;
  };

  return render();
};
