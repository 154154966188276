import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Responsive } from 'semantic-ui-react';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { IContactMeModel } from '../../../gen/ApiClients';
import { setFieldTouched } from '../../common/forms/FormsUtils';
import { TextareaField } from '../../common/forms/TextareaField';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TextInputField } from '../../common/forms/TextInputField';
import './ContactMeForm.scss';
import * as yup from 'yup';

interface IProps {
    fileNumber: string;
    onSend: (model: IContactMeModel) => void;
    onCancel: () => void;
}

export const ContactMeForm = ({ fileNumber, onSend, onCancel }: IProps) => {
    const locContext = useLocalizationContext();
    const validateSchema = yup.object<IContactMeModel>().shape<IContactMeModel>({
        companyName: yup
            .string()
            .required(locContext.fillIn),
        vatNumber: yup
            .string()
            .required(locContext.fillIn),
        email: yup
            .string()
            .email()
            .required(locContext.fillIn),
    });

    const onClickSubmit = async (props: FormikProps<IContactMeModel>, e: any) => {
        e.preventDefault();
        props.setSubmitting(true);
        setFieldTouched<IContactMeModel>('email', props);
        setFieldTouched<IContactMeModel>('vatNumber', props);
        setFieldTouched<IContactMeModel>('companyName', props);
        var isValid = await validateSchema.isValid(props.values);
        if (isValid) {
            await onSend(props.values);
        }
        props.setSubmitting(false);
    }

    return (
        <div>
            <Formik<IContactMeModel>
                initialValues={{ email: '', phone: '', comments: '' }}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={() => { }}>
                {(props) => (
                    <Form>
                        <div className="df-col dd-container">
                            <div className="dd-title">{locContext.contactMe}</div>
                            <div className="dd-label-file-number">{locContext.fileNumber}</div>
                            <div className="dd-file-number">{fileNumber}</div>
                            <TextInputField<IContactMeModel> label={locContext.companyName} xName="companyName" />
                            <TextInputField<IContactMeModel> label={locContext.labelContactPerson} xName="contactName" optional />
                            <TextInputField<IContactMeModel> label={locContext.vatNumber} xName="vatNumber" />
                            <TextInputField<IContactMeModel> label={locContext.email} xName="email" />
                            <TextInputField<IContactMeModel> label={locContext.phoneNumber} xName="phone" optional />
                            <TextareaField<IContactMeModel> label={locContext.comments} xName="comments" optional />
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <div className="df-row-ac jc-e">
                                    <div className="df-row-ac">
                                        <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                        <PrimaryButton disabled={props.isSubmitting} type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.send}</PrimaryButton>
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <div className="df-col">
                                    <PrimaryButton disabled={props.isSubmitting} type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.send}</PrimaryButton>
                                    <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                </div>
                            </Responsive>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
