import React, { useState } from 'react';
import { AddressModel, IAddressModel, IStopModel, ValidateClient } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { AddressFormComponent } from '../common/addresses/AddressFormComponent';
import { DateComponent } from '../common/DateComponent';
import { validateTimings } from '../common/Utils';

interface IProps {
    transportId: string;
    stopId?: string | undefined;
    stop?: IStopModel;
    onCancel: () => void;
    onSubmit: (model: IStopModel) => void;
}
export const ExtraStop = ({ transportId, stopId, stop, onCancel, onSubmit }: IProps) => {
    const validateClient = useClient(ValidateClient);
    const locContext = useLocalizationContext();
    const [subStep, setSubStep] = useState<number>(0);
    const [model, setModel] = useState<IStopModel | undefined>(stop);

    const handleSetAddress = (address: IAddressModel) => {
        setModel({ ...model, address: new AddressModel(address) });
        setSubStep(1);
    }
    const handleSetDate = async (date: Date, specifier: string) => {
        const x = { ...model, date: date, dateSpecifier: specifier };
        await onSubmit(x);
    }
    
    if (subStep === 0) {
        return <AddressFormComponent
            header={`${locContext.stopover} 1/3: ${locContext.addressDetails}`}
            address={stop ? stop.address : undefined}
            onPrevious={() => onCancel()}
            submit={handleSetAddress}
            modal
            previousText={locContext.cancel} />
    } else if (subStep === 1 && model) {
        return <DateComponent
            header={`${locContext.stopover} 2/3: ${locContext.date} & ${locContext.time}`}
            date={model && model.date ? model.date : new Date()}
            specifier={model && model.dateSpecifier ? model.dateSpecifier : 'Before'}
            onSubmit={handleSetDate}
            onPrevious={() => setSubStep(subStep - 1)}
            validate={(date, spec) => validateTimings(validateClient, transportId, stopId, model, date, spec)} />
    }
    return <div>Whoops, nothing found</div>
}