import React, { useContext, useState, useEffect } from 'react';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { TransportContext } from '../../../context/TransportSubmitContext';
import { ErrorScreen } from '../common/ErrorScreen';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { StopsViewComponent } from '../common/StopsViewComponent';
import { ModalContext } from '../../../context/ModalContext';
import { ContactMeForm } from './ContactMeForm';
import { TransportClient, ContactMeModel } from '../../../gen/ApiClients';
import { useClient } from '../../../hooks/useClient';
import { showSuccessToast } from '../../common/toast/ToastNotification';
import './Step4.scss';

export const Step4 = () => {
    const locContext = useLocalizationContext();
    const context = useContext(TransportContext);
    const [hasError, setHasError] = useState<boolean>(false);
    const modalContext = useContext(ModalContext);
    const client = useClient(TransportClient);

    useEffect(() => {
        if (context.calculatedResult) {
            if (context.calculatedResult.hasError === true || context.calculatedResult.fileId === undefined) {
                setHasError(true);
            }
        }
    }, [context.calculatedResult]);

    const onContactMe = () => {
        const fileNumber = context.state.fileNumber ? context.state.fileNumber : 'No filenumber found...';
        modalContext.open(
            <ContactMeForm
                fileNumber={fileNumber}
                onSend={async (model) => {
                    await client.contactMe(fileNumber, new ContactMeModel(model));
                    modalContext.close();
                    showSuccessToast(locContext.mailSent);
                }}
                onCancel={() => modalContext.close()}
            />
            , false);
    }

    if (!context.calculatedResult) {
        return null;
    } else if (hasError || context.calculatedResult.hasError) {
        console.log('has error = true');
        return <ErrorScreen />
    } else if (context.state === undefined) {
        console.log('state is null');
        return <ErrorScreen />
    }  else {
        return (
            <div className="blank-container calculated-container">
                <div className="header-text">{locContext.price}</div>
                <div className="label">{locContext.fileNumber}</div>
                <div className="info">{context.state.fileNumber}</div>
                {/* <div className="label">{locContext.date}</div>
                <div className="info">{toDefaultFormat(context.state.)}</div> */}
                <StopsViewComponent
                    className="calc-stops-view"
                    firstStop={context.state.firstStop}
                    extraStops={context.state.extraStops}
                    lastStop={context.state.lastStop}
                    showDividers={false}
                    addTopMargin={false}
                    hideLastDivider
                    formatStopAsLabel={true} />
                <div className="label">{locContext.price}</div>
                <div className="info">&euro; {context.state.amount}</div>
                <div className="df-row">
                    {context.state.costs ?
                        context.state.costs.map(t =>
                            <div className="df-col single-price-calc" key={t.description}>
                                <div className="label">{t.description}</div>
                                <div className="info">&euro; {t.amount}</div>
                            </div>)
                        : null}
                </div>
                <div className="label">{locContext.route}</div>
                <div className="info">{context.state.totalDistance} km</div>                
                <div className="df-row-ac jc-c blank-container-buttons">
                    <div className="df-row-ac">
                        <TextLinkButton className="previous-button" onClick={() => context.goBackToStep(3)}>{locContext.previous}</TextLinkButton>
                        <PrimaryButton rectanglePadding onClick={() => onContactMe()}>{locContext.contactMe}</PrimaryButton>
                    </div>
                </div>
            </div>
        );
    }
}