import React, { useState, useEffect } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { TextInputField } from '../../../common/forms/TextInputField';
import { AddressTypeAheadField } from '../../../common/forms/AddressTypeAheadField';
import { TextareaField } from '../../../common/forms/TextareaField';
import { TextLinkButton } from '../../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../../common/buttons/PrimaryButton';
import { IAddressModel, AddressModel, ISuggestion } from '../../../../gen/ApiClients';
import { clearNullOrUndefinedValues } from '../../../../infrastructure/Utils';
import { useLocalizationContext } from '../../../../hooks/useLocalizationContext';
import { setFieldTouched } from '../../../common/forms/FormsUtils';
import { createValidateSchemaWithHouseNumber, createValidateSchemaWithoutHouseNumber, setFields } from './Utils';
import './AddressFormComponent.scss';

interface IProps {
    address: IAddressModel | undefined;
    modal?: boolean;
    previousText?: string;
    onPrevious?: () => void;
    submit: (model: IAddressModel) => void;
    header: string;
}
export const AddressFormComponent = ({ address, modal, previousText, onPrevious, submit, header }: IProps) => {
    const locContext = useLocalizationContext();
    const [isValidatedOnce, setIsValidatedOnce] = useState<boolean>(false);
    const [hasSelAddressError, setHasSelAddressError] = useState<boolean>(false);
    const initValues = clearNullOrUndefinedValues<IAddressModel>(address, AddressModel);
    const [selAddress, setSelAddress] = useState<ISuggestion | undefined>(undefined);
    const validateSchema = selAddress && !selAddress.isExact
        ? createValidateSchemaWithHouseNumber(locContext)
        : createValidateSchemaWithoutHouseNumber(locContext);
    useEffect(() => {
        if (address) {
            setSelAddress(address);
        }
    }, [address]);

    const localSubmit = async (props: FormikProps<IAddressModel>, e: any) => {
        e.preventDefault();
        props.setSubmitting(true);
        setFieldTouched<IAddressModel>('company', props);
        setFieldTouched<IAddressModel>('contact', props);
        setFieldTouched<IAddressModel>('additionalInfo', props);
        setFieldTouched<IAddressModel>('notFoundHouseNumber', props);
        if (selAddress) {
            setIsValidatedOnce(true);
        }
        var isValid = await validateSchema.isValid(props.values);
        if (!selAddress) {
            setHasSelAddressError(true);
        } else if (props.isValid || (!props.dirty && isValid)) {
            const x = setFields(props.values, selAddress);
            submit(x);
        }
        props.setSubmitting(false);
    }

    return (
        <div className="blank-container pos-rel">
            <div className="header-text">{header}</div>
            <Formik<IAddressModel>
                initialValues={initValues}
                validationSchema={validateSchema}
                onSubmit={() => { }}>
                {(props) => (
                    <Form>
                        <div>
                            <TextInputField<IAddressModel> label={locContext.companyName} xName="company" placeholder={locContext.placeholderCompany} />
                            <AddressTypeAheadField<IAddressModel>
                                label={locContext.labelStreetNumberCityAndCode}
                                xName="street"
                                placeholder={locContext.placeholderAddress}
                                warningMessage={locContext.alsoFillInAHouseNumber}
                                value={selAddress}
                                onValueChanged={(val) => {
                                    if (val) {
                                        setHasSelAddressError(false);
                                    }
                                    setSelAddress(val);
                                }}
                                overrideError={hasSelAddressError ? locContext.selectAnAddress : undefined} />
                            {selAddress && selAddress.isExact === false && isValidatedOnce ?
                                <TextInputField<IAddressModel> label={locContext.houseNumber} xName="notFoundHouseNumber" />
                                : null}
                            <TextInputField<IAddressModel> label={locContext.labelAdditionalInfo} xName="additionalInfo" placeholder={locContext.placeholderAdditionalInfo} optional />
                            <TextInputField<IAddressModel> label={locContext.labelContactPerson} xName="contact" placeholder={locContext.placeholderContactPerson} />
                            <TextInputField<IAddressModel> label={locContext.phoneNumber} xName="phoneNumber" placeholder={locContext.placeholderPhonenumber} optional />
                            <TextareaField<IAddressModel> label={locContext.comments} xName="comment" optional />
                            <div className={`df-row-ac blank-container-buttons ${modal ? 'jc-e' : 'jc-c'}`}>
                                <div className="df-row-ac">
                                    {previousText === undefined || !onPrevious ? <div></div> :
                                        <TextLinkButton className="previous-button" onClick={onPrevious}>{previousText}</TextLinkButton>
                                    }
                                    <PrimaryButton rectanglePadding disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => localSubmit(props, e)}>{locContext.next}</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}