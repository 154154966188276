import React, { useContext, useEffect } from 'react';
import { TransportContext } from '../../context/TransportSubmitContext';
import './LandingPage.scss';

export const LandingPage = () => {
    const context = useContext(TransportContext);
    
    useEffect(() => {
        context.createNew();
    }, 
    // eslint-disable-next-line
    []);

   
    return (
        <div></div>
    );
}