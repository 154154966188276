import React, { useContext } from 'react';
import { Header } from './header/Header';
import { TransportContext } from '../../context/TransportSubmitContext';
import { Step1 } from './step1/Step1';
import { Step2 } from './step2/Step2';
import { Step4 } from './step4/Step4';
import { Step3 } from './step3/Step3';

export const Wrapper = ({ match }: any) => {
    const context = useContext(TransportContext);
    context.onMountOrRefresh(match.params.id);
    const render = () => {
        if (context.step === 1) {
            return <Step1 />
        }  else if (context.step === 2) {
            return <Step2 />
        } else if (context.step === 3) {
            return <Step3 />
        } else if (context.step === 4) {
            return <Step4 />
        } else {
            return <div>Whoops, nothing found</div>
        }        
    }

    return (
        <div className="df-col-ac stretch-ver">
            <Header />
            {render()}
        </div>
    );
}