import React from 'react';
import { IStopView } from '../../../gen/ApiClients';
import { Divider } from 'semantic-ui-react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { StopViewComponent } from './StopViewComponent';
import './StopsViewComponent.scss';

interface IProps {
    firstStop: IStopView | undefined;
    extraStops: IStopView[] | undefined;
    lastStop: IStopView | undefined;
    onEdit?: (stop: IStopView) => void;
    onDelete?: (stop: IStopView) => void;
    showDividers: boolean;
    addTopMargin: boolean;
    hideLastDivider?: boolean;
    className?: string;
    formatStopAsLabel: boolean;
}

export const StopsViewComponent = ({ firstStop, extraStops, lastStop, onEdit, onDelete, showDividers, addTopMargin, hideLastDivider = false, formatStopAsLabel, ...rest }: IProps) => {
    return (
        <div className={useCombineClassNames('df-col', rest)}>
            {firstStop ?
                <div className={`df-col ${addTopMargin ? ' stop-top-margin' : ''}`}>
                    <StopViewComponent
                        formatStopAsLabel={formatStopAsLabel}
                        stop={firstStop} />
                    {showDividers ? <Divider /> : null}
                </div>
                : null}
            {extraStops ?
                extraStops.map(t =>
                    onEdit && onDelete ?
                        <div key={`${t.id}-${t.order}`} className={`df-col ${addTopMargin ? ' stop-top-margin' : ''}`}>
                            <StopViewComponent
                                formatStopAsLabel={formatStopAsLabel}
                                stop={t}
                                onEdit={() => onEdit(t)}
                                onDelete={() => onDelete(t)} />
                            {showDividers ? <Divider /> : null}
                        </div>
                        :
                        <div key={`${t.id}-${t.order}`} className={`df-col ${addTopMargin ? ' stop-top-margin' : ''}`}>
                            <StopViewComponent
                                formatStopAsLabel={formatStopAsLabel}
                                stop={t} />
                            {showDividers ? <Divider /> : null}
                        </div>)
                : null}
            {lastStop ?
                // no edit or delete
                <div className={`df-col ${addTopMargin ? ' stop-top-margin' : ''}`}>
                    <StopViewComponent
                        formatStopAsLabel={formatStopAsLabel}
                        stop={lastStop} />
                    {showDividers && !hideLastDivider ? <Divider /> : null}
                </div>
                : null}
        </div>
    );
}