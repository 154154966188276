import React, { useState, useContext, useEffect } from 'react';
import { empty, tryCatchWithLoading } from '../infrastructure/Utils';
import { useClient } from '../hooks/useClient';
import { RouterContext } from './RouterContext';
import { useLocalizationContext } from '../hooks/useLocalizationContext';
import { ModalContext } from './ModalContext';
import { BaseConfirmation } from '../components/common/modal/BaseConfirmation';
import { IStopModel, ITransportFlow, ICalculateResult, TransportClient, StopModel } from '../gen/ApiClients';
import * as routes from '../routes';
import { FullScreenLoader } from '../components/common/loaders/FullScreenLoader';

interface IProps {
    step: number;
    progress: number;
    subStep: number;
    nextSubstep: () => void;
    previousSubStep: () => void;
    setFirstStop: (model: IStopModel) => void;
    addExtraStop: (model: IStopModel) => void;
    editExtraStop: (stopId: string, model: IStopModel) => void;
    deleteExtraStop: (stopId: string) => void;
    gotoStep2: () => void;
    setLastStop: (model: IStopModel) => void;
    calculate: () => void;
    onMountOrRefresh: (id: string) => void;
    goBackToStep: (step: number) => void;
    goBackToStartOfStep: (step: number) => void;
    state: ITransportFlow;
    calculatedResult: ICalculateResult | undefined;
    cancel: () => void;
    createNew: () => void;
    isSubmitting: boolean;
}

const fallback: IProps = {
    step: 0,
    progress: 0,
    subStep: 0,
    nextSubstep: empty,
    previousSubStep: empty,
    setFirstStop: empty,
    addExtraStop: empty,
    editExtraStop: empty,
    deleteExtraStop: empty,
    gotoStep2: empty,
    setLastStop: empty,
    calculate: empty,
    onMountOrRefresh: empty,
    goBackToStep: empty,
    goBackToStartOfStep: empty,
    state: {},
    calculatedResult: {},
    cancel: empty,
    createNew: empty,
    isSubmitting: false,
}

export const TransportContext = React.createContext<IProps>(fallback);

const mapSubSteps = new Map<number, number>([[1, 2], [2, 1], [3, 0], [4, 0]]);

export const TransportProvider = ({ children }: any) => {
    const [isLoading, localSetIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const [subStep, setSubStep] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const [id, setId] = useState<string>('');
    const [state, setState] = useState<ITransportFlow>({});
    const [calculateResult, setCalculateResult] = useState<ICalculateResult | undefined>(undefined);

    const client = useClient(TransportClient);
    const routerContext = useContext(RouterContext);
    const modalContext = useContext(ModalContext);
    const locContext = useLocalizationContext();

    // eslint-disable-next-line
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
        // eslint-disable-next-line
        history.go(1);
    };

    let timer: NodeJS.Timeout;
    const setIsLoading = (isLoading: boolean) => {
        if (isLoading) {
            setIsSubmitting(true);
            timer = setTimeout(() => {
                localSetIsLoading(true);
            }, 1000);
        } else {
            clearTimeout(timer);
            localSetIsLoading(false);
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        const x = mapSubSteps.get(step);
        if (x !== undefined) {
            if (x === 0) {
                setProgress(0);
            } else {
                setProgress((subStep / x) * 100);
            }
        }
    }, [subStep, step]);

    const onNextSubstep = () => {
        setSubStep(subStep + 1);
    }

    const onPreviousSubstep = () => {
        setSubStep(subStep - 1);
    }

    const loadState = async () => {
        setState(await tryCatchWithLoading(client.get(id), setIsLoading, locContext.serverError)); //500 error
    }

    const onSetFirstStop = async (model: IStopModel) => {
        await tryCatchWithLoading(client.addFirstStop(id, new StopModel(model)), setIsLoading, locContext.serverError);
        await loadState();
    }

    const onAddExtraStop = async (model: IStopModel) => {
        await tryCatchWithLoading(client.addExtraStop(id, new StopModel(model)), setIsLoading, locContext.serverError);
        await loadState();
    }

    const onEditExtraStop = async (stopId: string, model: IStopModel) => {
        await tryCatchWithLoading(client.editStop(id, stopId, new StopModel(model)), setIsLoading, locContext.serverError);
        await loadState();
    }

    const onDeleteExtraStop = async (stopId: string) => {
        await tryCatchWithLoading(client.deleteStop(id, stopId), setIsLoading, locContext.serverError);
        await loadState();
    }

    const onGotoStep2 = () => {
        setStep(2);
        setSubStep(0);
    }

    const onSetLastStop = async (model: IStopModel) => {
        await tryCatchWithLoading(client.addLastStop(id, new StopModel(model)), setIsLoading, locContext.serverError);
        await loadState();
        setStep(3);
        setSubStep(0);
    }

    const onCalculate = async () => {
        setCalculateResult(undefined);
        const result = await tryCatchWithLoading(client.calculate(id), setIsLoading, locContext.serverError);
        setCalculateResult(result);
        await loadState();
        setStep(4);
        setSubStep(0);
    }

    const onGoBackToStep = (step: number) => {
        setStep(step);
        const x = mapSubSteps.get(step);
        setSubStep(x ? x : 0);
    }
    const onGoBackToStartOfStep = (step: number) => {
        setStep(step);
        setSubStep(0);
    }

    const onCancel = () => {
        if (step < 4) {
            modalContext.open(<BaseConfirmation
                title={locContext.leavePageTitle}
                description={locContext.leavePageDescription}
                confirmText={locContext.noStay}
                cancelText={locContext.yesLeave}
                confirm={() => modalContext.close()}
                cancel={() => {
                    modalContext.close();
                    routerContext.navigate(routes.LandingPageRoute);
                }}
            />, false)
        } else {
            routerContext.navigate(routes.LandingPageRoute);
        }
    }

    const onMountOrRefresh = async (paramsId: string) => {
        // happens always, but also in case of browser refresh
        if (!paramsId) {
            console.log('no id provided');
            return;
        }
        setId(paramsId);
        if (id !== paramsId) {
            const x = await tryCatchWithLoading(client.get(paramsId), setIsLoading, locContext.serverError);
            setState(x);
            if (x.lastStop) {
                setStep(3);
            } else if (x.extraStops && x.extraStops.length > 0) {
                setStep(2);
                const x = mapSubSteps.get(2);
                setSubStep(x ? x : 0);
            } else if (x.firstStop) {
                setStep(2);
                setSubStep(0);
            } else {
                setStep(1);
            }
        }
    }

    const onCreateNew = async () => {
        const id = await tryCatchWithLoading(client.create(), setIsLoading, locContext.serverError);
        setId(id);
        routerContext.navigate(routes.calculateNewRoute(id));
        setState({});
        setStep(1);
        setSubStep(0);
    }

    return (
        <TransportContext.Provider
            value={{
                step: step,
                subStep: subStep,
                nextSubstep: onNextSubstep,
                previousSubStep: onPreviousSubstep,
                progress: progress,
                setFirstStop: onSetFirstStop,
                addExtraStop: onAddExtraStop,
                editExtraStop: onEditExtraStop,
                deleteExtraStop: onDeleteExtraStop,
                gotoStep2: onGotoStep2,
                setLastStop: onSetLastStop,
                calculate: onCalculate,
                onMountOrRefresh: onMountOrRefresh,
                goBackToStep: onGoBackToStep,
                goBackToStartOfStep: onGoBackToStartOfStep,
                state: state,
                calculatedResult: calculateResult,
                cancel: onCancel,
                createNew: onCreateNew,
                isSubmitting: isSubmitting,
            }}>
            <div className="pos-rel stretch-ver stretch-hor">
                {isLoading ? <FullScreenLoader dim /> : null}
                {children}
            </div>
        </TransportContext.Provider>
    );
}