import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { LanguageStore } from '../infrastructure/LanguageStore';
import { ApiUrl } from '../keys';

export function useClient<T>(type: new (baseUrl?: string, instance?: AxiosInstance) => T): T {
	let inst = axios.create();
	inst.interceptors.request.use(languageInterceptor, error => Promise.reject(error));
	return new type(ApiUrl, inst);
}

function languageInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
	const language = LanguageStore.get();
	if (language) {
		config.headers.Language = language;
	}
	return config;
}
