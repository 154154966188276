import React, { useContext } from 'react';
import { Logo } from '../../common/Logo';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { ProgressBar } from './ProgressBar';
import { TransportContext } from '../../../context/TransportSubmitContext';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { Responsive } from 'semantic-ui-react';
import { LocalizationContext } from '../../../context/LocalizationContext';
import { CustomDropDown } from '../../common/dropdown/CustomDropdown';
import { IOption } from '../../../infrastructure/Models';
import { ModalContext } from '../../../context/ModalContext';
import './Header.scss';


export const Header = () => {
    const context = useContext(TransportContext);
    const locContext = useLocalizationContext();
    const locContextFull = useContext(LocalizationContext);
    const modalContext = useContext(ModalContext);
    const options: IOption<"en" | "fr" | "nl">[] = [
        { key: "en", value: "en", text: "EN" },
        { key: "fr", value: "fr", text: "FR" },
        { key: "nl", value: "nl", text: "NL" },
    ]

    const renderDropDown = () => {
        return (
            <CustomDropDown<("en" | "fr" | "nl") >
                onClear={() => { }}
                placeholder="Taal/Language/Langue"
                options={options}
                onSelect={(val) => {
                    locContextFull.setLanguage(val);
                    modalContext.close();
                }}
                selected={locContextFull.getLanguage()}
                stretch={false}
                searchable={false}
                clearable={false}
                small={true} />
        );
    }

    const selectLanguage = () => {
        modalContext.open(<SelectLanguagePopup cont={renderDropDown()} />)
    }

    return (
        <div className="header-container">
            <Responsive minWidth={Responsive.onlyTablet.minWidth} className="df-row-afs">
                <div className="left">
                    <Logo />
                </div>
                <ProgressBar />
                <div className="right df-row-ac">
                    {renderDropDown()}
                    <TextLinkButton className="cancel-button" onClick={() => context.cancel()}>{locContext.startNew}</TextLinkButton>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-col stretch-hor">
                    <ProgressBar />
                    <div className="df-row-ac jc-sb padding-10px">
                        <TextLinkButton className="language-button" onClick={() => selectLanguage()}>Taal/Language/Langue</TextLinkButton>
                        <TextLinkButton className="cancel-button" onClick={() => context.cancel()}>{locContext.startNew}</TextLinkButton>
                    </div>
                </div>
            </Responsive>
        </div>
    );
}

interface ISlProps {
    cont: JSX.Element;
}
const SelectLanguagePopup = ({ cont }: ISlProps) => {
    return (
        <div className="df-col stretch-ver">
            <div className="sl-title">Taal/Language/Langue</div>
            {cont}
        </div>
    );
}
