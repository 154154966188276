import React, { useEffect } from 'react';
import { build } from '../build';
import { BuildClient } from '../gen/ApiClients';
import { useClient } from '../hooks/useClient';
import { clearCacheAndReloadApplication } from '../infrastructure/Utils';

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const AppUpdateWall: React.FC = ({ children }: any) => {
	const buildClient = useClient(BuildClient);

	useEffect(() => {
		const load = async () => {
			const x = await buildClient.current();
			if (build < x) {
				await sleep(2000);
				clearCacheAndReloadApplication();
			}
		};
		load();
		// eslint-disable-next-line
	}, []);

	return children;
};
