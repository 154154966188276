import { IStrings } from './IStrings';

export const fr: IStrings = {
    add: `Ajouter`,
    addressDetails: `Détails d'adresse`,
    addStop: `Ajouter un stop`,
    after: `Après`,
    alsoFillInAHouseNumber: `Introduire aussi un numéro de maison`,
    at: `A`,
    before: `Devat/Avant`,
    busySearchingAddress: `Recherche d'adresses`,
    busyTyping: `Dactylographie`,
    cancel: `Annuler`,
    change: `Ajuster`,
    checkYourData: `Vérifiez vos coordonnées`,
    choose: `Choisir`,
    comments: `Remarques`,
    companyName: `Nom de l'entreprise`,
    confirmAddStopover: `Voulez-vous ajouter une escale?`,
    confirmDeleteStop: `Êtes-vous sûr de vouloir supprimer cet arrêt?`,
    contactMe: `Contactez-moi`,
    date: `Date`,
    delete: `Supprimer`,
    deleteStop: `Supprimer le stop`,
    email: `E-mailadres`,
    extraStop: `Arrêt supplémentaire`,
    fileNumber: `Numéro du dossier`,
    fillIn: `Remplir`,
    firstStop: `Premier arrêt`,
    goToUnload: `Décharger`,
    hours: `Heures`,
    houseNumber: `Numéro de maison`,
    labelAdditionalInfo: `Informations supplémentaires ( numéro de boîte, étage,…)`,
    labelContactPerson: `Personne sur place`,
    labelStreetNumberCityAndCode: `Adresse (rue, numéro, ville et code postal)`,
    lastStop: `Dernier stop`,
    leavePageDescription: `Si vous quittez cette page, vous perdez vos modifications, Êtes-vous sûr?`,
    leavePageTitle: `Quitte rla page`,
    load: `Chargement`,
    mailSent: `Votre demande a été envoyée à Euro-Sprinters!`,
    max6stops: `Vous pouvez entrer jusqu'à 6 arrêts.\nVoulez-vous ajouter plus? Contactez le service client.`,
    maxLengthValidation: `La longueur est limitée à max  {0} caractères`,
    next: `Suivant`,
    no: `Non`,
    noAddressFound: `Aucune adresse trouvée`,
    noStay: `Non, restez sur cette page`,
    optional: `Facultatif`,
    overview: `Vue d'ensemble`,
    phoneNumber: `Numéro de téléphone`,
    placeholderAdditionalInfo: `Informations supplémentaires`,
    placeholderAddress: `Entrez une adresse`,
    placeholderCompany: `Entrez le nom de l'entreprise`,
    placeholderContactPerson: `Entrez le nom de la personne de contact`,
    placeholderPhonenumber: `Entrez un numéro de téléphone`,
    previous: `Précédent`,
    price: `Prix`,
    route: `Route`,
    search: `Recherche`,
    select: `Sélectionner`,
    selectAnAddress: `Sélectionnez une adresse`,
    selectDateAndTime: `Sélectionnez la date/l'heure`,
    selectTime: `Sélectionnez une heure`,
    send: `Expédier`,
    serverError: `Une erreur inconnue est survenue sur le serveur. Veuillez réessayer. Si l'erreur persiste, veuillez contacter Euro-Sprinters.`,
    startNew: `Recommencer`,
    stopover: `Stop entre`,
    time: `Le temps`,
    unload: `Décharger`,
    validateCompanyName: `Entrez le nom de l'entreprise`,
    validateContactPerson: `Entrez la personne de contact`,
    validateHouseNumberIfNotFoundAbove: `Entrez un numéro de rue s'il ne se trouve pas ci-dessus`,
    validateTime: `Sélectionnez une heure`,
    validateTimeSelector: `Sélectionnez de / à / après`,
    vatNumber: `Numéro de TVA`,
    yes: `Oui`,
    yesDelete: `Oui, supprimer`,
    yesLeave: `Oui, rester`,
}
