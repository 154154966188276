import { IStrings } from './IStrings';

export const nl: IStrings = {
    add: `Voeg toe`,
    addressDetails: `Adresdetails`,
    addStop: `Voeg een stop toe`,
    after: `Na`,
    alsoFillInAHouseNumber: `Vul ook een huisnummer in`,
    at: `Om`,
    before: `Voor`,
    busySearchingAddress: `Adres zoeken`,
    busyTyping: `Bezig met typen`,
    cancel: `Annuleer`,
    change: `Aanpassen`,
    checkYourData: `Controleer je gegevens`,
    choose: `Kies`,
    comments: `Opmerkingen`,
    companyName: `Bedrijfsnaam`,
    confirmAddStopover: `Wil je een tussenstop toevoegen?`,
    confirmDeleteStop: `Ben je zeker dat je deze stop wil verwijderen?`,
    contactMe: `Contacteer me`,
    date: `Datum`,
    delete: `Verwijderen`,
    deleteStop: `Stop verwijderen`,
    email: `E-mailadres`,
    extraStop: `Extra stop`,
    fileNumber: `Dossiernummer`,
    fillIn: `Vul in`,
    firstStop: `Eerste stop`,
    goToUnload: `Ga naar lossen`,
    hours: `Uren`,
    houseNumber: `Huisnummer`,
    labelAdditionalInfo: `Extra info (busnr, verdieping, ...)`,
    labelContactPerson: `Persoon ter plaatse`,
    labelStreetNumberCityAndCode: `Adres (straat, huisnummer, stad en postcode)`,
    lastStop: `Laatste stop`,
    leavePageDescription: `Als je deze pagina verlaat ben je je wijzigingen verloren. Ben je zeker?`,
    leavePageTitle: `Pagina verlaten`,
    load: `Laden`,
    mailSent: `Jouw aanvraag is verstuurd naar Euro-Sprinters!`,
    max6stops: `Je kan tot max. 6 stops ingeven.\nWil je meer toevoegen? Contacteer dan de klantendienst.`,
    maxLengthValidation: `Lengte is beperkt tot max. {0} karakters`,
    next: `Volgende`,
    no: `Nee`,
    noAddressFound: `Geen adres gevonden`,
    noStay: `Nee, blijf op deze pagina`,
    optional: `Optioneel`,
    overview: `Overzicht`,
    phoneNumber: `Telefoonnummer`,
    placeholderAdditionalInfo: `Extra info`,
    placeholderAddress: `Geef een adres in`,
    placeholderCompany: `Geef de naam van het bedrijf`,
    placeholderContactPerson: `Geef de naam van de contactpersoon`,
    placeholderPhonenumber: `Geef een telefoonnummer`,
    previous: `Vorige`,
    price: `Prijs`,
    route: `Route`,
    search: `Zoek`,
    select: `Selecteer`,
    selectAnAddress: `Selecteer een adres`,
    selectDateAndTime: `Selecteer datum & tijd`,
    selectTime: `Selecteer uur`,
    send: `Verzenden`,
    serverError: `Een ongekende fout is opgetreden op de server.\nGelieve het opnieuw te proberen.\nAls de fout blijft aanhouden, gelieve Euro-Sprinters te contacteren.`,
    startNew: `Begin opnieuw`,
    stopover: `Tussenstop`,
    time: `Tijd`,
    unload: `Lossen`,
    validateCompanyName: `Vul de naam van het bedrijf in`,
    validateContactPerson: `Vul contactpersoon in`,
    validateHouseNumberIfNotFoundAbove: `Vul een huisnummer in als het hierboven niet wordt gevonden`,
    validateTime: `Selecteer een tijdstip`,
    validateTimeSelector: `Selecteer vanaf/om/na`,
    vatNumber: `BTW-nummer`,
    yes: `Ja`,
    yesDelete: `Ja, verwijder`,
    yesLeave: `Ja, ga weg`,
}
